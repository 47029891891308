import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Youtube = makeShortcode("Youtube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Avez-vous remarqué que les pailles en plastiques ont disparu de la circulation ?`}</p>
    <p>{`Cela est dû à la promulgation de la Loi AGEC !`}</p>
    <p>{`L'objectif final : sortir du plastique à usage unique en 2040 ! `}</p>
    <p>{`Savez-vous en quoi consiste cette loi ? On est allés interrogés quelques parisiens !`}</p>
    <Youtube youtubeVideoId="a-WmDAfBbBo" mdxType="Youtube"></Youtube>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      